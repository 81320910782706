<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <TypeOfExistingRadiatorFormPart @result="onResult" @change="onChange" />

      <template v-if="isStandardExistingRadiator">
        <h2 class="form-modal__part-title">
          Y a-t-il des plans pour remplacer les radiateurs standard ?
        </h2>

        <IntendsToReplaceRadiatorFormPart
          @result="onResult"
          @change="onChange"
        />
      </template>
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import IntendsToReplaceRadiatorFormPart from 'chimera/heatPump/components/form/parts/intendsToReplaceRadiator/IntendsToReplaceRadiatorFormPart.vue'
import TypeOfExistingRadiatorFormPart from 'chimera/heatPump/components/form/parts/typeOfExistingRadiator/TypeOfExistingRadiatorFormPart.vue'
import NextStep from '~/pages/quotes-requests/type-pompe-a-chaleur'

export default {
  name: 'TypeOfHeatingFormStep',

  components: {
    TypeOfExistingRadiatorFormPart,
    IntendsToReplaceRadiatorFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  computed: {
    /**
     * @returns {boolean}
     */
    isStandardExistingRadiator() {
      if (
        this.$store.getters['lead/getData'](
          'type-of-existing-radiator',
          false,
        ) === false
      ) {
        return false
      }

      return (
        this.$store.getters['lead/getData']('type-of-existing-radiator')[0]
          .value ===
        this.$t('field.type-of-existing-radiator.options.regular-radiator')
      )
    },
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
