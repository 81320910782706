<template>
  <FormModal title="Type des radiateurs existants" show-back-btn>
    <template #body>
      <TypeOfExistingRadiatorFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfExistingRadiatorFormStep from '~/components/form/steps/typeOfExistingRadiator/TypeOfExistingRadiatorFormStep'

export default {
  components: {
    TypeOfExistingRadiatorFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type de radiateur',
      headDescription:
        "Indiquez quel type d'installation de pompe à chaleur vous recherchez.",
      path: '/quotes-requests/type-radiateur',
      progressValue: 60,
      checkoutStep: 4,
    }
  },
}
</script>
