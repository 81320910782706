<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <TypeOfHeatingFormPart @result="onResult" @change="onChange" />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import TypeOfHeatingFormPart from 'chimera/heatPump/components/form/parts/typeOfHeating/TypeOfHeatingFormPart'
import TypeOfHeatPumpPage from '~/pages/quotes-requests/type-pompe-a-chaleur'
import TypeOfRadiatorPage from '~/pages/quotes-requests/type-radiateur'

export default {
  name: 'TypeOfHeatingFormStep',

  components: {
    TypeOfHeatingFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      let route

      const value =
        this.$store.getters['lead/getData']('type-of-heating')[0].value
      const skipStep = ![
        this.$t('field.type-of-heating.options.floor-heating-and-radiators'),
        this.$t('field.type-of-heating.options.radiators'),
      ].includes(value)

      if (skipStep) {
        // Clear radiator data, in case the user went back on that step.
        // Since we skip it, we don’t want to keep the data.
        this.$store.dispatch('lead/rm', 'type-of-radiator')
        this.$store.dispatch('lead/rm', 'type-of-existing-radiator')
        this.$store.dispatch('lead/rm', 'intends-to-replace-radiator')
        route = TypeOfHeatPumpPage
      } else {
        route = TypeOfRadiatorPage
      }

      this.routeTo(route)
    },
  },
}
</script>
