<template>
  <FormModal
    title="Quel type de chauffage est raccordé à la pompe à chaleur ?"
    show-back-btn
  >
    <template #body>
      <TypeOfHeatingFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfHeatingFormStep from '~/components/form/steps/typeOfHeating/TypeOfHeatingFormStep.vue'

export default {
  components: {
    TypeOfHeatingFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type verwarming',
      headDescription:
        "Indiquez quel type d'installation de pompe à chaleur vous recherchez.",
      path: '/quotes-requests/type-de-chauffage',
      progressValue: 45,
      checkoutStep: 3,
    }
  },
}
</script>
